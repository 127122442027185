<style lang="scss" src="../../styles/savings.scss" scoped></style>

<template>
  <div class="overdraft-fees-page">
    <!-- HEADER -->
    <HeaderSavingsDetails
      title="Overdraft fees"
    />

    <!-- MAIN -->
    <main class="m-b-20" :class="{'offer': isLoadingRequestCallback}">

      <!-- SUBHEADER INFO -->
      <spinner v-if='isLoadingRequestCallback' />

      <div v-else class="ui container">
        <SubHeaderSavingsDetails />
        <!-- OFFER INFO -->
        <OfferSavingsCallbackRequestedDetails v-if="isRequestedCallback"/>

        <OfferSavingsOverdraftDetails
          @request-callback="requestCallbackClickHandler"
          v-else
        />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { postCompanyRequestSavingsCallback } from '@/api/company'
import HeaderSavingsDetails from '../../components/HeaderSavingsDetails'
import SubHeaderSavingsDetails from '../../components/SubHeaderSavingsDetails'
import OfferSavingsOverdraftDetails from '../../components/OfferSavingsOverdraftDetails'
import OfferSavingsCallbackRequestedDetails from '../../components/OfferSavingsCallbackRequestedDetails'

export default {
  name: 'OverdraftFees',
  components: {
    HeaderSavingsDetails,
    SubHeaderSavingsDetails,
    OfferSavingsCallbackRequestedDetails,
    OfferSavingsOverdraftDetails
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    })
  },
  data () {
    return {
      categoryName: 'Overdraft Fees',
      isRequestedCallback: false,
      isLoadingRequestCallback: false
    }
  },
  methods: {
    requestCallbackClickHandler () {
      this.isLoadingRequestCallback = true
      const { companyId, categoryName } = this
      return postCompanyRequestSavingsCallback({ companyId, categoryName })
        .then(res => {
          this.isRequestedCallback = true
          this.isLoadingRequestCallback = false
        })
        .catch(e => {
          this.isRequestedCallback = false
          this.isLoadingRequestCallback = true
          console.log('e', e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .offer {
    min-height:349px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
