<template>
  <div class="offer-container">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 bdr-divider">
        <div class="message-container">
          <h2 class="ui text dark">I want to compare overdraft costs</h2>
          <p>Browse our business current account offerings to see which overdraft solution works best for your business.</p>
          <router-link :to="{ name: 'savings-banking' }" class="btn btn-sm btn-primary btn-callback m-b-10">
            Compare bank accounts
          </router-link>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="message-container">
          <h2 class="ui text dark">I want to learn about alternatives</h2>
          <p>Our funding specialists can walk you through alternative funding solutions to Overdrafts and how they might work for your business.</p>
          <button class="btn btn-sm btn-secondary" type="button" v-promise-btn @click="requestCallbackClickHandler">
            Request Callback
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferSavingsOverdraftDetails',
  methods: {
    requestCallbackClickHandler() {
      this.$emit('request-callback')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.offer-container {
  margin-top: 1rem;
  border-radius: 12px;
  background-color: $color-white;
  box-shadow: 0 2px 12px 0 rgba(51, 51, 79, 0.06);
  margin-bottom: 3rem;

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .message-container {
    // margin: 16px 0 16px 40px;
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 204px;
    h2 {
      font-weight: 400;
    }
    button {
      width: 219px;
    }
  }

  .bdr-divider {
    border-right: 1px solid var(--color-primary-100);

    @media only screen and (max-width: $breakpoint-md-max) {
      margin: 0rem;
    }
  }

  .btn-callback {
    width: 219px;
  }
  .col-xs-12 {
    &:not(:last-child) {
      border-bottom: 2px solid $default-border-color;
    }
    .box {
      margin-bottom: 2rem;
    }
  }
}
</style>
